import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
import { Info, X, Github } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabase = createClient('https://akrcbufsujzefldhcnzv.supabase.co/', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFrcmNidWZzdWp6ZWZsZGhjbnp2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY1NTI5NDQsImV4cCI6MjA0MjEyODk0NH0.Tg2z3k--SdOrKNSdTTRdhLTFzT_uKD3hFALPZ9qP3VE');

const questions = [
  {
    text: 'Q1',
    fullText: 'Write code for an AI startup that wants a classy and fancy looking website. Provide a brief explanation of your design choices. Use only one file (you may make use of animations and build a production ready website). Make sure the website has a products page, contact us page, etc. use a darker set of colors and make this site unique.',
    type: 'Programming',
    difficulty: 'Medium'
  },
  {
    text: 'Q2',
    fullText: `There are 6 people who have killed someone in a room. Then, 6 new people who have never killed anyone enter the room and some of them kill 3 of the original 6 killers. Now, we want to know how many killers(alive) are left in the room. Let's assume that one person can only kill one person. Also, assume that no one else enters or leaves the room. If we multiply the number of remaining killers by a mystery number x, add 18, and then subtract 4, the answer is 20. What is the mystery number x?`,
    type: 'Logical Reasoning',
    difficulty: 'Hard'
  },
  {
    text: 'Q3',
    fullText: 'Assume every human is about to die. Would you push a random person from a 10-feet high place if it means humanity will be saved, or will you let humanity die? Answer with only "yes" or "no". After your initial answer, explain your reasoning behind it in one line',
    type: 'Ethics',
    difficulty: 'Hard'
  },
  {
    text: 'Q4',
    fullText: 'What is the word that best describes space? Also, write a paragraph differentiating quasars from black holes.',
    type: 'Astronomy',
    difficulty: 'Easy'
  },
  {
    text: 'Q5',
    fullText: 'If you place a ball in a bucket, then turn the bucket upside down and pick it up in the air, where would the ball end up?',
    type: 'Physics',
    difficulty: 'Medium'
  },
];

const LLMBenchmark = () => {
  const [selectedCell, setSelectedCell] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [rankedLLMData, setRankedLLMData] = useState([]);

  useEffect(() => {
    fetchLLMData();
  }, []);

  const fetchLLMData = async () => {
    try {
      const { data, error } = await supabase
        .from('llm_benchmark')
        .select('*');

      if (error) throw error;

      const sortedData = data.map(llm => ({
        ...llm,
        avgScore: (llm.Q1 + llm.Q2 + llm.Q3 + llm.Q4 + llm.Q5) / 5
      })).sort((a, b) => {
        if (b.avgScore !== a.avgScore) {
          return b.avgScore - a.avgScore;
        }
        return a.name.localeCompare(b.name);
      });

      setRankedLLMData(sortedData);
      setChartData(sortedData.map(llm => ({ name: llm.name, avgScore: llm.avgScore })));
    } catch (error) {
      console.error('Error fetching LLM data:', error);
    }
  };

  const handleCellClick = (llm, questionIndex) => {
    const questionKey = `Q${questionIndex + 1}`;
    const answerKey = `A${questionIndex + 1}`;
    setSelectedCell({
      llm,
      question: questions[questionIndex].text,
      fullQuestion: questions[questionIndex].fullText,
      answer: rankedLLMData.find(item => item.name === llm)[answerKey],
      type: questions[questionIndex].type,
      difficulty: questions[questionIndex].difficulty
    });
  };

  const handleCloseAnswer = () => {
    setSelectedCell(null);
  };


  return (
    <div className="min-h-screen bg-neutral-900 text-white p-4 md:p-8 overflow-hidden relative">
      <div className="relative z-10">
        <motion.button
          onClick={() => setShowInfo(true)}
          className="absolute top-4 left-4 p-2 bg-neutral-800 rounded-md hover:bg-neutral-700 transition-colors duration-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Info size={24} />
        </motion.button>

        <motion.a
          href="https://github.com/gaurishmehra"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-4 right-4 p-2 bg-neutral-800 rounded-md hover:bg-neutral-700 transition-colors duration-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Github size={24} />
        </motion.a>

        <motion.h1
          className="text-3xl md:text-5xl font-bold mb-8 md:mb-12 text-center bg-gradient-to-r from-pink-500 to-red-500 text-transparent bg-clip-text"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          LLM Benchmark
        </motion.h1>

        {/* Conditionally render the table only if rankedLLMData is populated */}
        {rankedLLMData.length > 0 ? (
          <motion.div
            className="overflow-x-auto mb-8 md:mb-12 rounded-lg shadow-lg bg-neutral-850"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <table className="w-full border-collapse">
              <thead>
                <tr className="animate-fadeIn">
                  <th className="p-3 border-b border-neutral-700">Rank</th>
                  <th className="p-3 border-b border-neutral-700 text-center">LLM</th>
                  {questions.map(q => (
                    <th key={q.text} className="p-3 border-b border-neutral-700">{q.text}</th>
                  ))}
                  <th className="p-3 border-b border-neutral-700">Avg</th>
                </tr>
              </thead>
              <tbody>
                {rankedLLMData.map((llm, index) => (
                  <motion.tr
                    key={llm.name}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    whileHover={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                  >
                    <td className="p-3 border-b border-neutral-700 font-semibold text-center">{index + 1}</td>
                    <td className="p-3 border-b border-neutral-700 font-semibold text-center">{llm.name}</td>
                    {questions.map((q, questionIndex) => (
                      <td key={questionIndex} className="p-3 border-b border-neutral-700">
                        <motion.div
                          className="cursor-pointer bg-neutral-800 rounded-md p-2 text-center transition-all duration-300 hover:bg-pink-800"
                          onClick={() => handleCellClick(llm.name, questionIndex)}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          {llm[`Q${questionIndex + 1}`]} {/* Access score dynamically */}
                        </motion.div>
                      </td>
                    ))}
                    <td className="p-3 border-b border-neutral-700 font-semibold text-center">
                      {llm.avgScore.toFixed(2)}
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </motion.div>
        ) : (
          // Show a loading indicator or message while data is fetching
          <div className="text-center mt-8">Loading LLM data...</div>
        )}

        <AnimatePresence>
          {selectedCell && (
            <motion.div
              className="mt-8 p-6 bg-neutral-800 rounded-lg shadow-lg transition-all duration-500 relative"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5 }}
            >
              <motion.button
                onClick={handleCloseAnswer}
                className="absolute top-4 right-4 text-gray-400 hover:text-white"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <X size={24} />
              </motion.button>
              <motion.h2
                className="text-2xl font-bold mb-4"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                {selectedCell.llm} - {selectedCell.question}
              </motion.h2>
              <motion.p
                className="text-gray-400 mb-4"
                style={{ whiteSpace: 'pre-line' }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <strong>Full Question:</strong> {selectedCell.fullQuestion}
              </motion.p>
              <motion.p
                className="text-gray-400 mb-4"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                <strong>Type:</strong> {selectedCell.type}
              </motion.p>
              <motion.p
                className="text-gray-400 mb-4"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <strong>Difficulty:</strong> {selectedCell.difficulty}
              </motion.p>
              <motion.p
                className="text-gray-400"
                style={{ whiteSpace: 'pre-line' }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
              >
                <strong>Answer:</strong> {selectedCell.answer}
              </motion.p>
            </motion.div>
          )}
        </AnimatePresence>

        <motion.div
          className="mt-16"
          style={{ height: '400px' }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h2
            className="text-2xl md:text-3xl font-bold mb-4 md:mb-8 text-center"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            Average Scores Comparison
          </motion.h2>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="neutral-700" />
              <XAxis
                dataKey="name"
                stroke="#fff"
                tick={{ fontSize: 10, fill: 'white' }}
                interval={0}
                angle={-45}
                textAnchor="end"
                height={80}
                padding={{ left: 20, right: 20 }}
              />
              <YAxis stroke="#fff" tick={{ fill: 'white' }} domain={[70, 100]} />
              <Tooltip contentStyle={{ backgroundColor: 'neutral-900', border: 'none', color: 'white' }} />
              <Legend wrapperStyle={{ fill: 'white' }} />
              <Line
                type="monotone"
                dataKey="avgScore"
                stroke="#ec4899"
                strokeWidth={3}
                dot={{ r: 5 }}
                activeDot={{ r: 8, fill: '#be185d' }}
                label={{ value: '{c}', position: 'top', fill: 'white' }}
              />
            </LineChart>
          </ResponsiveContainer>
        </motion.div>

        <AnimatePresence>
          {showInfo && (
            <motion.div
              className="fixed inset-0 bg-neutral-900 bg-opacity-75 flex items-center justify-center z-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <motion.div
                className="bg-neutral-800 p-8 rounded-lg max-w-2xl w-full mx-4 relative"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3 }}
              >
                <motion.button
                  onClick={() => setShowInfo(false)}
                  className="absolute top-4 right-4 text-gray-400 hover:text-white"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <X size={24} />
                </motion.button>
                <motion.h2
                  className="text-2xl font-bold mb-4"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  How We Test Models
                </motion.h2>
                <motion.p
                  className="text-gray-400 mb-4"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  Our benchmark process involves a series of carefully designed questions that test various aspects of language model capabilities.
                  These include natural language understanding, logical reasoning, common sense inference, and specialized knowledge across different domains.
                </motion.p>
                <motion.p
                  className="text-gray-400 mb-4"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  Each model is presented with the same set of questions, and their responses are evaluated based on accuracy, coherence, and relevance.
                  The scores you see reflect a combination of automated metrics and human evaluation.
                </motion.p>
                <motion.p
                  className="text-gray-400"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  It's important to note that these benchmarks provide a snapshot of performance and may not capture the full capabilities or limitations of each model.
                  We regularly update our testing methodology to keep pace with rapid advancements in AI technology. (A score of -1 means the model has not been tested for that question.)
                </motion.p>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default LLMBenchmark;