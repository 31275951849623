import React from 'react';
import LLMBenchmark from './LLMBenchmark';

function App() {
  return (
    <div className="App">
      <LLMBenchmark />
    </div>
  );
}

export default App;